<template>
  <div>
    <v-container fluid>
      <div class="main">


        <v-row v-if="isLoading">
          <v-col v-for="item in 3" :key="item" cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else align="center">

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-5">
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="gifty" size="45" class="rounded-lg">
                      <v-icon color="white">mdi-form-select</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ total }}
                    </div>
                    Campagnes
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>


          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-5">
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="primary" size="45" class="rounded-lg">
                      <v-icon color="white">mdi-file-search-outline</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ investigations }}
                    </div>
                    Enquêtes
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>


          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-5">
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="success" size="45" class="rounded-lg">
                      <v-icon color="white">mdi-clipboard-check-outline</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ tasks }}
                    </div>
                    Tâches
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12">

            <v-card class="rounded-lg shadow">
              <v-overlay :value="overlay" absolute color="white" opacity="0.5">
                <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-title>
                <div>
                  <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Rechercher..."
                      rounded
                      single-line

                  />
                </div>
                <v-spacer/>

                <v-btn color="grey"
                       text
                       @click="getCampaigns">
                  <v-icon left>mdi-refresh</v-icon>
                  Mettre à jour
                </v-btn>

                <v-btn color="gifty"
                       dark
                       class="rounded-lg ml-2"
                       depressed
                       to="/opus/campaigns/create">
                  <v-icon left>mdi-plus</v-icon>
                  Nouvelle campagne
                </v-btn>


              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>
                <v-card-text class="pa-0">
                  <v-simple-table v-if="campaigns.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-no-wrap">Image</th>
                        <th class="text-no-wrap">Tite</th>
                        <th>Début</th>
                        <th>Fin</th>
                        <th class="text-no-wrap">Date de création</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(campaign ,index) in campaigns" :key="index">

                        <td>
                          <v-img class="rounded-lg grey lighten-4 my-3"
                                 width="50"
                                 v-if="campaign.images && campaign.images.length"
                                 :src="fileUrl + campaign.images[0]"
                                 height="50"></v-img>

                          <v-img v-else
                                 class="rounded-lg grey lighten-4 my-3"
                                 width="50"
                                 height="50"></v-img>
                        </td>

                        <td>
                             {{ campaign.name['fr'] }}
                        </td>

                        <td>
                          <v-chip small label text-color="success"
                                  class="bg-subtle-success font-weight-medium">
                            {{ campaign.start_date }}
                          </v-chip>
                        </td>

                        <td>
                          <v-chip small label text-color="red"
                                  class="bg-subtle-error font-weight-medium">
                            {{ campaign.end_date }}
                          </v-chip>
                        </td>

                        <td class="text-no-wrap">
                          {{ campaign.created_at }}
                        </td>

                        <td>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon
                                     v-bind="attrs"
                                     v-on="on"
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense>

                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon>mdi-pencil-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Modifier</v-list-item-title>
                              </v-list-item>

                              <v-list-item @click="remove(campaign.id)">
                                <v-list-item-icon>
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Supprimer</v-list-item-title>
                              </v-list-item>

                            </v-list>
                          </v-menu>
                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults @reload="getCampaigns"/>
                  </div>
                </v-card-text>

                <v-divider/>

                <v-card-actions>

                  <div :style="{width: '120px'}">
                    <v-select v-model="perPage"
                              :items="[10,20,30,50,100]"
                              dense
                              hide-details
                              label="Ligne par page"
                              outlined></v-select>
                  </div>

                  <v-spacer/>

                  <v-pagination v-model="pagination.current"
                                :length="pagination.total"
                                circle
                                total-visible="8"
                                @input="getCampaigns"></v-pagination>

                </v-card-actions>

              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <vue-easy-lightbox escDisabled
                         moveDisabled
                         :visible="visible"
                         :imgs="image"
                         :index="index"
                         @hide="visible = false">
      </vue-easy-lightbox>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";

export default {
  components: {NoResults},
  data() {
    return {
      keyword: '',
      id: '',
      overlay: false,
      isLoading: false,
      campaigns: [],

      filter: {},
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      investigations: 0,
      tasks: 0,

      //lightbox
      fileUrl: process.env.VUE_APP_FILE_URL,
      image: [],
      visible: false,
      index: 0
    };
  },
  methods: {
    getCampaigns() {
      this.isLoading = true
      HTTP.get('/v1/opus/campaigns?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          keyword: this.keyword
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        this.campaigns = res.data.data.data

        this.investigations = res.data.counts.investigations
        this.tasks = res.data.counts.tasks

        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    deleteItem() {
      this.overlay = true;
      HTTP.delete("/v1/opus/campaigns/"+this.id+"/delete").then(() => {
        this.overlay = false;
        let i = this.campaigns.findIndex((el) => el.id === this.id);
        this.$delete(this.campaigns, i);
        this.id = null;
        this.$successMessage = "Cet élément a été supprimé avec succès.";
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
  },
  created() {
    this.getCampaigns();
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem();
        this.$is_confirm = false;
      }
    },
    perPage() {
      this.pagination.current = 1
      this.getCampaigns();
    },
    keyword() {
      this.getCampaigns();
    },
  }
};
</script>

<style scoped>
.cursor-zomm-in {
  cursor: zoom-in;
}

.bg-subtle-warning {
  background: rgba(255, 171, 2, 0.15) !important;
}

.bg-subtle-success {
  background: rgba(121, 215, 15, 0.15) !important;
}

.bg-subtle-error {
  background: rgba(255, 36, 66, 0.15) !important;
}
</style>
